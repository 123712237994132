<script setup>
import AleaButton from "./AleaButton.vue"

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(["itemClicked"])
</script>

<template>
  <AleaButton
    class="rounded-lg flex flex-col gap-1 items-center"
    variant="ghost"
    size="mobileMenu"
    :route="item.route"
    :key="item.id"
    @click="emit('itemClicked')">
    <!-- icon -->
    <component
      :is="item.icon"
      class="size-5 shrink-0"
      :class="[
        {'text-violet-500': item.active},
        {'text-neutral-500': !item.active},
      ]" />

    <!-- label -->
    <span
      class="text-xs"
      :class="[
        {'text-violet-500': item.active},
        {'text-neutral-500': !item.active},
      ]"
      >{{ item.title }}</span
    >
  </AleaButton>
</template>
