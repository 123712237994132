<script setup>
import AleaView from "@aleahealth/common/apps/components/alea/AleaView.vue"
import {House, Users, Stethoscope, ContactRound, CalendarClock, Mail, File, Handshake} from "lucide-vue-next"

const menuItems = [
  {
    title: "dashboard",
    icon: House,
    route: {name: "DashboardView"},
  },
  {
    title: "users",
    icon: Users,
    route: {name: "UsersView"},
  },
  {
    title: "providers",
    icon: Stethoscope,
    route: {name: "ProvidersView"},
  },
  {
    title: "clients",
    icon: ContactRound,
    route: {name: "ClientsView"},
  },
  {
    title: "sessions",
    icon: Handshake,
    route: {name: "TherapiesSessionsView"},
  },
  {
    title: "slots",
    icon: CalendarClock,
    route: {name: "BookingSlotsView"},
  },
  {
    title: "emails",
    icon: Mail,
    route: {name: "EmailsView"},
  },
  {
    title: "files",
    icon: File,
    route: {name: "FilesView"},
  },
]
</script>

<template><AleaView :menuItems /></template>
