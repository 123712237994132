<script setup>
import {computed} from "vue"
import {ToastViewport} from "radix-vue"
import {cn} from "../../../lib/utils"

const props = defineProps(["class"])

const delegatedProps = computed(() => {
  const {class: _, ...delegated} = props

  return delegated
})
</script>

<template>
  <ToastViewport
    v-bind="delegatedProps"
    :class="
      cn(
        'fixed top-0 z-[100] max-h-screen flex-col-reverse p-2 flex-center left-0 w-full right-0 _sm:bottom-0 _sm:right-0 _sm:top-auto _sm:flex-col',
        props.class
      )
    " />
</template>
