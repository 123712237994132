<script setup>
import {Switch} from "../shadcn-vue/switch"
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../shadcn-vue/form"

const model = defineModel()
const props = defineProps({
  label: {
    type: [Boolean, String],
  },
  caption: {
    type: [Boolean, String],
  },
})
</script>

<template>
  <div>
    <div class="flex flex-row items-center gap-2">
      <Switch v-model:checked="model" />

      <!-- label -->
      <FormLabel v-if="label">{{ label }}</FormLabel>
    </div>

    <!-- caption -->
    <FormDescription v-if="caption">{{ caption }}</FormDescription>
  </div>
</template>
