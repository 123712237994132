export default [
  {
    id: "morning",
    title: "Morning (before 12pm)",
  },
  {
    id: "afternoon",
    title: "Afternoon (12-6pm)",
  },
  {
    id: "evening",
    title: "Evening (after 6pm)",
  },
  {
    id: "anytime",
    title: "I'm flexible",
  },
]
