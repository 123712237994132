export default [
  {
    id: "actionOriented",
    titleClient: 'Action-Oriented',
    subtitleClient: 'Works with you to take clear, practical steps that help shift your thoughts and behaviors, focusing on real progress you can see and feel.',
    titleProvider: "Action oriented",
  },
  {
    id: "relationalReflective",
    titleClient: 'Relational and Reflective',
    subtitleClient: 'Your therapist will actively listen and help you explore your emotions and behaviors deeply, while supporting you in building stronger, more meaningful relationships.',
    titleProvider: "Relational and reflective",
  },
  {
    id: "creativeIntegrative",
    titleClient: 'Creative and Integrative',
    subtitleClient: 'Incorporates a mix of techniques, like mindfulness and breathing exercises, to create a balanced approach that supports your overall well-being.',
    titleProvider: "Creative and integrative",
  },
  {
    id: "noPreference",
    titleClient: 'No preference',
    titleProvider: "No preference",
  },
]
