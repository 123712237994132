<script setup>
import {computed} from "vue"
import {
  CalendarDate,
  DateFormatter,
  getLocalTimeZone,
  parseDate,
  today,
} from "@internationalized/date"
import {Calendar as CalendarIcon} from "lucide-vue-next"
import {Button} from "../shadcn-vue/button"
import {Popover, PopoverContent, PopoverTrigger} from "../shadcn-vue/popover"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../shadcn-vue/form"
import AleaCalendar from "./AleaCalendar.vue"
import {cn} from "../../lib/utils"

const df = new DateFormatter("en-US", {
  dateStyle: "long",
})

const model = defineModel()
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  label: {
    type: [Boolean, String],
  },
  placeholder: {
    type: [Boolean, String],
  },
  caption: {
    type: [Boolean, String],
  },
  min: {
    type: [Date, String],
  },
  max: {
    type: [Date, String],
  },
})
const emit = defineEmits(["update:modelValue"])

const value = computed({
  get: () => (model.value ? parseDate(model.value) : undefined),
  set: (val) => val,
})

function onUpdate(date) {
  emit("update:modelValue", date.toString())
}
</script>

<template>
  <FormItem class="flex flex-col">
    <!-- label -->
    <FormLabel class="text-neutral-700 text-lg" v-if="label">{{ label }}</FormLabel>

    <!-- ui -->
    <Popover>
      <PopoverTrigger as-child>
        <Button
          variant="outline"
          :class="
            cn(
              'justify-start text-left font-normal bg-white',
              !value && 'text-muted-foreground'
            )
          ">
          <CalendarIcon class="mr-2 h-4 w-4" />
          <span class="" v-if="value">{{
            df.format(value.toDate(getLocalTimeZone()))
          }}</span>
          <span class="text-neutral-400" v-else>Pick a date</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent class="flex w-auto flex-col gap-y-2 p-2"  :allowCollisions="true" :collisionPadding="{top: 100}" :alignOffset="200" prioritizePosition>
        <AleaCalendar
          class="border-none"
          v-model="value"
          initial-focus
          :min
          :max
          @update:modelValue="onUpdate" />
      </PopoverContent>
    </Popover>

    <!-- caption -->
    <FormDescription v-if="caption">
      {{ caption }}
    </FormDescription>

    <!-- message -->
    <FormMessage />
  </FormItem>
</template>
