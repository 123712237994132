export default [
  {
    id: "christianity",
    title: "Christianity",
  },
  {
    id: "islam",
    title: "Islam",
  },
  {
    id: "judaism",
    title: "Judaism",
  },
  {
    id: "hinduism",
    title: "Hinduism",
  },
  {
    id: "buddhism",
    title: "Buddhism",
  },
  {
    id: "atheism",
    title: "Atheism",
  },
  {
    id: "agnostic",
    title: "Agnostic"
  },
  {
    id: "other",
    title: "Other"
  }
]
