export default [
  {
    id: "none",
    title: "None",
  },
  {
    id: "some",
    title: "Some experience",
  },
  {
    id: "extensive",
    title: "Extensive experience",
  },
]
