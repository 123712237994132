import {cva} from "class-variance-authority"

export {default as Button} from "./Button.vue"

export const buttonVariants = cva(
  "inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-violet-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-violet-500 dark:focus-visible:ring-violet-300",
  {
    variants: {
      variant: {
        default:
          "bg-violet-600 text-violet-50 hover:bg-violet-900/90 dark:bg-violet-50 dark:text-violet-900 dark:hover:bg-violet-50/90",
        destructive:
          "bg-red-500 text-violet-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-violet-50 dark:hover:bg-red-900/90",
        outline:
          "border border-violet-200 bg-transparent hover:bg-violet-100 hover:text-violet-900 dark:border-violet-800 dark:bg-violet-500 dark:hover:bg-violet-800 dark:hover:text-violet-50",
        outlineDestructive:
          "border border-red-500 bg-transparent hover:bg-red-100 hover:text-red-900 dark:border-red-900 dark:bg-red-900 dark:hover:bg-red-800 dark:hover:text-red-50 text-red-500",
        secondary:
          "bg-violet-100 text-violet-900 hover:bg-violet-100/80 dark:bg-violet-800 dark:text-violet-50 dark:hover:bg-violet-800/80",
        ghost:
          "hover:bg-violet-100 hover:text-violet-900 dark:hover:bg-violet-800 dark:hover:text-violet-50",
        ghostSelected:
          "hover:bg-violet-100 hover:text-violet-900 dark:hover:bg-violet-800 dark:hover:text-violet-50 text-violet-500",
        ghostDestructive:
          "hover:bg-red-100 hover:text-red-900 dark:hover:bg-red-800 dark:hover:text-red-50 text-red-500",
        link: "text-violet-900 underline-offset-4 hover:underline dark:text-violet-50",
      },
      size: {
        default: "h-10 px-4 py-2",
        xs: "h-7 rounded px-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
        mobileMenu: "h-12 w-16"
      },
      align: {
        left: "justify-start",
        center: "justify-center",
        right: "justify-end",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      align: "center",
    },
  }
)
