import enumsTherapyGoals from "../enums/therapyGoals.js"

const optionsTherapyGoals = enumsTherapyGoals.map((therapyGoal) => {
  return {
    value: therapyGoal.id,
    label: therapyGoal.titleProvider,
  }
})

export default optionsTherapyGoals
