import enumsTherapyStyles from "../enums/therapyStyles.js"

const optionsTherapyStyles = enumsTherapyStyles.map(
  (therapyStyle) => {
    return {
      value: therapyStyle.id,
      label: therapyStyle.titleClient,
      sublabel: therapyStyle.subtitleClient // Sublabel
    }
  }
)

export default optionsTherapyStyles
