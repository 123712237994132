export default [
  {
    id: 'individual',
    title: 'Individual',
    subtitle: 'For myself'
  },
  {
    id: 'couple',
    title: 'Couple',
    subtitle: 'For me and my partner'
  }
  // {
  //   id: "family",
  //   title: "Family",
  // },
  // {
  //   id: "minor",
  //   title: "Minor",
  // },
]
