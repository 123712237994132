import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {usersGet} from "@aleahealth/common"

export const useUserStore = defineStore("user", () => {
  // state
  const user = ref({})
  const userAuth = ref({})
  const userAcl = ref({})
  const userSignedIn = ref(false)
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const userLoaded = computed(() => status.value == "loaded")
  const userId = computed(() => user?.value?.id)
  const userRole = computed(() => userAcl.value?.role)
  const userIsClient = computed(() => userRole.value == "client")
  const userIsProvider = computed(() => userRole.value == "provider")
  const userIsAdmin = computed(() => userRole.value == "admin")

  // actions
  async function get({userId}) {
    const res = await usersGet(userId, {
      include: ["currentTherapy", "currentProvider", "profile"],
      notifySuccess: false,
    })
    user.value = res.response.data
    status.value = "loaded"
  }

  return {
    // state
    user,
    userAuth,
    userAcl,
    userSignedIn,
    status,
    unsubscribe,

    // getters
    userLoaded,
    userId,
    userRole,
    userIsClient,
    userIsProvider,
    userIsAdmin,

    // actions
    get,
    // userAllowed,
    // update,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
