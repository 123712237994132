export default [
  {
    id: "awaitingPayment",
    title: "Awaiting payment",
  },
  // {
  //   id: "awaitingConfirmation",
  //   title: "awaiting confirmation",
  // },
  {
    id: "confirmed",
    title: "Confirmed",
  },
  {
    id: "inProgress",
    title: "In progress",
  },
  {
    id: "completed",
    title: "Completed",
  },
  {
    id: "noShow",
    title: "No show",
  },
  {
    id: "cancelled",
    title: "Cancelled",
  },
]
