export default [
  {
    id: "cbt",
    title: "Cognitive-behavioural therapy (CBT)",
  },
  {
    id: "psychoanalytic",
    title: "Psychoanalytic therapy",
  },
  {
    id: "psychodynamic",
    title: "Psychodynamic therapy",
  },
  {
    id: "systemicRelational",
    title: "Systemic-relational therapy",
  },
  {
    id: "gestalt",
    title: "Gestalt therapy",
  },
  {
    id: "briefStrategic",
    title: "Brief-strategic therapy",
  },
  {
    id: "transactionalAnalysis",
    title: "Transactional analysis",
  },
  {
    id: "cognitiveConstructivist",
    title: "Cognitive constructivist therapy",
  },
  {
    id: "dialecticalBehavior",
    title: "Dialectical behavior therapy (DBT)",
  },
  {
    id: "humanistic",
    title: "Humanistic therapy",
  },
  {
    id: "existential",
    title: "Existential therapy",
  },
  {
    id: "solutionFocusedBrief",
    title: "Solution-focused brief therapy (SFBT)",
  },
  {
    id: "mindfulnessBased",
    title: "Mindfulness-based therapy",
  },
  {
    id: "acceptanceAndCommitment",
    title: "Acceptance and commitment therapy (ACT)",
  },
  {
    id: "interpersonal",
    title: "Interpersonal therapy (IPT)",
  },
  {
    id: "familySystems",
    title: "Family systems therapy",
  },
  {
    id: "narrative",
    title: "Narrative therapy",
  },
  {
    id: "art",
    title: "Art therapy",
  },
  {
    id: "play",
    title: "Play therapy (for children)",
  },
  {
    id: "behavioral",
    title: "Behavioral therapy",
  },
  {
    id: "reality",
    title: "Reality therapy",
  },
]
