<script setup>
import {computed} from "vue"
import {Check, ChevronDown} from "lucide-vue-next"
import {Label} from "../shadcn-vue/label"
import {cn} from "../../lib/utils.js"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../shadcn-vue/form"
import {Checkbox} from "../shadcn-vue/checkbox"

const model = defineModel()
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  label: {
    type: [Boolean, String],
  },
  subLabel: {
    type: [Boolean, String],
  },
  placeholder: {
    type: [Boolean, String],
  },
  caption: {
    type: [Boolean, String],
  },
  showFilter: {
    type: Boolean,
    default: false,
  },
})

const selectedValues = computed({
  get: () => model.value || [],
  set: (newValue) => {
    model.value = newValue
  },
})

const selectedLabels = computed(() => {
  return selectedValues.value.map((value) => {
    return props.options.find((option) => option.value === value).label
  })
})

function onClickOption(option) {
  const newSelectedValues = [...selectedValues.value]
  const index = newSelectedValues.indexOf(option.value)
  if (index > -1) {
    newSelectedValues.splice(index, 1)
  } else {
    newSelectedValues.push(option.value)
  }
  selectedValues.value = newSelectedValues
}
</script>

<template>
  <FormItem class="flex flex-col">
    <!-- label -->
    <FormLabel class="text-neutral-700 text-lg" v-if="label">{{ label }}</FormLabel>
    <FormLabel v-if="subLabel" class="text-neutral-500 mt-1">{{ subLabel }}</FormLabel>

    <!-- ui -->
    <div
  class="flex items-start space-x-2 border-neutral-200 border rounded-lg px-3 py-3 cursor-pointer"
  :class="[
    {
      'hover:border hover:border-violet-200 bg-white': !model?.includes(
        option.value
      ),
    },
    {
      'border border-violet-500 outline outline-violet-500 bg-violet-50':
        model?.includes(option.value),
    },
  ]"
  v-for="option in options"
  :key="option.value"
  :value="option.label"
  @click="onClickOption(option)"
>
  <Checkbox
    :checked="model?.includes(option.value)"
    @update:checked="handleChange"
  />
  <div class="flex flex-col pl-1">
    <Label
      class="text-neutral-600 cursor-pointer"
      :for="`${name}-${option.value}`"
      >{{ option.label }}
    </Label>
    <Label
      v-if="option.sublabel"
      class="text-neutral-400 cursor-pointer"
      :for="`${name}-${option.value}`"
      >{{ option.sublabel }}
    </Label>
  </div>
</div>

    <!-- caption -->
    <FormDescription v-if="caption">
      {{ caption }}
    </FormDescription>

    <!-- message -->
    <FormMessage />
  </FormItem>
</template>
