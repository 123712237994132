import enumsTherapyStyles from "../enums/therapyStyles.js"

const optionsTherapyStyles = enumsTherapyStyles.map(
  (therapyStyle) => {
    return {
      value: therapyStyle.id,
      label: therapyStyle.titleProvider,
    }
  }
)

export default optionsTherapyStyles
