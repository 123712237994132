<script setup>
import {Label} from "../shadcn-vue/label"

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  for: {
    type: String,
  },
})
</script>

<template>
  <Label :for>{{ label }}</Label>
</template>
