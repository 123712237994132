import enumsReligions from "../enums/religions.js"

const optionsReligions = enumsReligions.map((religion) => {
  return {
    value: religion.id,
    label: religion.title,
  }
})

export default optionsReligions
