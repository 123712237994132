import enumsTherapyLicenseTypes from "../enums/therapyLicenseTypes.js"

const optionsTherapyLicenseTypes = enumsTherapyLicenseTypes.map(
  (therapyLicenseType) => {
    return {
      value: therapyLicenseType.id,
      label: therapyLicenseType.title,
    }
  }
)

export default optionsTherapyLicenseTypes
