<script setup>
import {useAppStore} from "@aleahealth/common/apps/stores/app.js"
import {storeToRefs} from "pinia"
import {initApi} from "@aleahealth/common"
import AleaProgress from "@aleahealth/common/apps/components/alea/AleaProgress.vue"
import AleaToaster from "@aleahealth/common/apps/components/alea/AleaToaster.vue"

const appStore = useAppStore()

const {app} = storeToRefs(appStore)

initApi({appId: "admin"})
</script>

<template>
  <transition
    enter-active-class="transition-opacity duration-500"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-500"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <!-- loading overlay -->
    <div class="fixed inset-0 z-50 flex bg-white flex-center" v-if="!app.auth.initialUserCheckDone">
      <AleaProgress />
    </div>
  </transition>

  <!-- main view -->
  <div class="">
    <RouterView />
  </div>

  <!-- toast notifications -->
  <AleaToaster />
</template>
