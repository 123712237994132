<script setup>
import {computed} from "vue"
import {CalendarRoot, useDateFormatter, useForwardPropsEmits} from "radix-vue"
import {createDecade, createYear, toDate} from "radix-vue/date"
import {getLocalTimeZone, today, parseDate} from "@internationalized/date"
import {useVModel} from "@vueuse/core"
import {
  CalendarCell,
  CalendarCellTrigger,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHead,
  CalendarGridRow,
  CalendarHeadCell,
  CalendarHeader,
  CalendarHeading,
} from "../shadcn-vue/calendar"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn-vue/select"
import {cn} from "../../lib/utils.js"

const props = defineProps({
  modelValue: {
    type: [Object, undefined],
    default: undefined,
  },
  placeholder: {
    type: Object,
    default: () => today(getLocalTimeZone()),
  },
  weekdayFormat: {
    type: String,
    default: "short",
  },
  class: {
    type: [String, undefined],
    default: undefined,
  },
  markedDates: {
    type: Array,
    default: () => [],
  },
  min: {
    type: [Date, String],
  },
  max: {
    type: [Date, String],
  },
})

const emits = defineEmits(["update:modelValue"])

const minValue = computed(() => {
  if (!props.min) return undefined

  if (props.min === "today") {
    return today(getLocalTimeZone())
  } else if (props.min instanceof Date) {
    return new CalendarDate(
      props.min.getFullYear(),
      props.min.getMonth() + 1,
      props.min.getDate()
    )
  } else {
    return parseDate(props.min)
  }
})
const maxValue = computed(() => {
  if (!props.max) return undefined

  if (props.max === "today") {
    return today(getLocalTimeZone())
  } else if (props.max instanceof Date) {
    return new CalendarDate(
      props.max.getFullYear(),
      props.max.getMonth() + 1,
      props.max.getDate()
    )
  } else {
    return parseDate(props.max)
  }
})

const delegatedProps = computed(() => {
  const {class: _, placeholder: __, ...delegated} = props

  return delegated
})

const placeholder = useVModel(props, "modelValue", emits, {
  passive: true,
  defaultValue: today(getLocalTimeZone()),
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

const formatter = useDateFormatter("en")
</script>

<template>
  <CalendarRoot
    v-slot="{date, grid, weekDays}"
    v-model:placeholder="placeholder"
    v-bind="forwarded"
    week-starts-on="1"
    :minValue
    :maxValue
    :class="cn('rounded-md border p-3 inline-flex flex-col', props.class)">
    <CalendarHeader>
      <CalendarHeading
        class="inline-flex w-full items-center justify-between gap-2">
        <Select
          :default-value="placeholder.month.toString()"
          @update:model-value="
            (v) => {
              if (!v || !placeholder) return
              if (Number(v) === placeholder?.month) return
              placeholder = placeholder.set({
                month: Number(v),
              })
            }
          ">
          <SelectTrigger aria-label="Select month" class="w-[60%]">
            <SelectValue placeholder="Select month" />
          </SelectTrigger>
          <SelectContent class="max-h-[200px]">
            <SelectItem
              v-for="month in createYear({dateObj: date})"
              :key="month.toString()"
              :value="month.month.toString()">
              {{ formatter.custom(toDate(month), {month: "long"}) }}
            </SelectItem>
          </SelectContent>
        </Select>

        <Select
          :default-value="props.placeholder.year.toString()"
          @update:model-value="
            (v) => {
              if (!v || !placeholder) return
              if (Number(v) === placeholder?.year) return
              placeholder = placeholder.set({
                year: Number(v),
              })
            }
          ">
          <SelectTrigger aria-label="Select year" class="w-[40%]">
            <SelectValue placeholder="Select year" />
          </SelectTrigger>
          <SelectContent class="max-h-[200px]">
            <SelectItem
              v-for="yearValue in createDecade({
                dateObj: date,
                startIndex: -100,
                endIndex: 100,
              })"
              :key="yearValue.toString()"
              :value="yearValue.year.toString()">
              {{ yearValue.year }}
            </SelectItem>
          </SelectContent>
        </Select>
      </CalendarHeading>
    </CalendarHeader>

    <div class="flex flex-col space-y-4 pt-4 sm:flex-row sm:gap-x-4 sm:gap-y-0">
      <CalendarGrid v-for="month in grid" :key="month.value.toString()">
        <CalendarGridHead>
          <CalendarGridRow>
            <CalendarHeadCell v-for="day in weekDays" :key="day">
              {{ day }}
            </CalendarHeadCell>
          </CalendarGridRow>
        </CalendarGridHead>
        <CalendarGridBody class="grid">
          <CalendarGridRow
            v-for="(weekDates, index) in month.rows"
            :key="`weekDate-${index}`"
            class="mt-2 w-full">
            <CalendarCell
              v-for="weekDate in weekDates"
              :key="weekDate.toString()"
              :date="weekDate">
              <span
                class="absolute left-[calc(50%-3px)] bottom-[2px] size-[6px] bg-violet-500 block rounded-full"
                v-if="markedDates.includes(weekDate.toString())"></span>
              <CalendarCellTrigger :day="weekDate" :month="month.value" />
            </CalendarCell>
          </CalendarGridRow>
        </CalendarGridBody>
      </CalendarGrid>
    </div>
  </CalendarRoot>
</template>
