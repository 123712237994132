<script setup>
import {ref} from "vue"
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../shadcn-vue/form"
import {Input} from "../shadcn-vue/input"

const model = defineModel()
const props = defineProps({
  label: {
    type: [Boolean, String],
  },
  sublabel: {
    type: [Boolean, String],
  },
  placeholder: {
    type: [Boolean, String],
  },
  caption: {
    type: [Boolean, String],
  },
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: "text",
  },
})
</script>

<template>
  <FormItem>
    <!-- label -->
    <FormLabel class="text-neutral-700 text-lg block" v-if="label">{{ label }}</FormLabel>
    <FormLabel v-if="sublabel" class="text-neutral-500 mt-1 block">{{ sublabel }}</FormLabel>

    <!-- ui -->
    <FormControl>
      <Input v-model="model" :name="name" :placeholder="placeholder" :type="type" autocomplete="off" />
    </FormControl>

    <!-- caption -->
    <FormDescription v-if="caption">{{ caption }}</FormDescription>

    <!-- message -->
    <FormMessage />
  </FormItem>
</template>
