export default [
  {
    id: "pending",
    title: "Pending",
  },
  {
    id: "verified",
    title: "Verified",
  },
  {
    id: "rejected",
    title: "Rejected",
  },
]
