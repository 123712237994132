<script setup>
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../shadcn-vue/card"

const props = defineProps({
  title: {
    type: [Boolean, String],
    default: false,
  },
  description: {
    type: [Boolean, String],
    default: false,
  },
  footer: {
    type: [Boolean, String],
    default: false,
  },
  classContent: {
    type: [String, undefined],
    default: undefined,
  },
})
</script>

<template>
  <Card>
    <CardHeader v-if="title || description">
      <CardTitle v-if="title">{{ title }}</CardTitle>
      <CardDescription v-if="description">{{ description }}</CardDescription>
    </CardHeader>
    <CardContent :class="classContent"><slot /></CardContent>
    <CardFooter v-if="footer">{{ footer }}</CardFooter>
  </Card>
</template>
