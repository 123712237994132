<script setup>
import {computed} from "vue"
import {Label} from "../shadcn-vue/label"
import {
  FormItem,
  FormLabel,
  FormDescription,
  FormMessage,
} from "../shadcn-vue/form"
import {RadioGroup, RadioGroupItem} from "../shadcn-vue/radio-group"
import {getCamelCase} from "@aleahealth/common/utils/index.js"

const model = defineModel()
const props = defineProps({
  name: {
    type: String,
  },
  options: {
    type: Object,
  },
  label: {
    type: [Boolean, String, Number],
  },
  sublabel: {
    type: [Boolean, String, Number],
  },
  caption: {
    type: String,
  },
  preselectFirst: {
    type: Boolean,
    default: false,
  },
})

const optionsEnriched = computed(() =>
  props.options.map((option) => {
    if (typeof option === "string") {
      return {
        value: getCamelCase(option),
        label: option,
      }
    }
    return option
  })
)

// set default value
if (props.preselectFirst) {
  model.value = props.options[0].value
}

function getLabel(label) {
  return label.replaceAll(/\*(.*?)\*/g, "<strong>$1</strong>")
}
</script>

<template>
  <FormItem>
    <div class="flex flex-col">
      <FormLabel class="text-neutral-700 text-lg" v-if="label">{{ label }}</FormLabel>
      <FormLabel v-if="sublabel" class="text-neutral-500 mt-1">{{ sublabel }}</FormLabel>
    </div>
    <RadioGroup v-model="model">
      <div
        class="flex items-center space-x-2 border-neutral-200 border rounded-lg px-1 py-3 cursor-pointer"
        :class="[
          {
            'hover:border hover:border-violet-200 bg-white':
              model !== option.value,
          },
          {
            'border border-violet-500 outline outline-violet-500 bg-violet-50':
              model === option.value,
          },
        ]"
        @click="model = option.value"
        v-for="option in optionsEnriched"
        :key="option.value"
      >
        <RadioGroupItem
          class="shrink-0 hidden"
          :id="`${name}-${option.value}`"
          :value="option.value"
        />
        <div class="flex flex-col">
          <Label
            class="text-neutral-600 cursor-pointer"
            :for="`${name}-${option.value}`"
          >
            <span v-html="getLabel(option.label)"></span>
          </Label>
          <Label
            v-if="option.sublabel"
            class="text-neutral-400 cursor-pointer"
            :for="`${name}-${option.value}`"
          >
            <span v-html="getLabel(option.sublabel)"></span>
          </Label>
        </div>
      </div>
    </RadioGroup>
    <FormDescription v-if="caption">{{ caption }}</FormDescription>
    <FormMessage />
  </FormItem>
</template>
