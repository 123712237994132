export default [
  {
    id: "client",
    title: "Client",
  },
  {
    id: "provider",
    title: "Provider",
  },
  {
    id: "admin",
    title: "Admin",
  }
]
