import {createRouter, createWebHistory} from "vue-router"
import {routes} from "./routes"
import {signOut} from "firebase/auth"
import {auth} from "@aleahealth/common/apps/firebase/index.js"
import {onAuthStateChanged} from "firebase/auth"
import {initApi} from "@aleahealth/common"
import {useAppStore} from "@aleahealth/common/apps/stores/app.js"
import {useUserStore} from "@aleahealth/common/apps/stores/user.js"

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  let user = auth.currentUser
  if (requiresAuth && !user) {
    return {name: "AuthView"}
  }
})

// router.afterEach((to, from) => {})

onAuthStateChanged(auth, async (firebaseUser) => {
  // console.log("onAuthStateChanged", firebaseUser)
  const appStore = useAppStore()
  const userStore = useUserStore()
  // const profileStore = useProfileStore()
  const app = appStore.app

  const currentRoute = router.currentRoute
  const currentRouteName = currentRoute.value.name
  const currentRouteRequiresAuth = currentRoute.value.meta.requiresAuth || false

  if (firebaseUser?.uid) {
    // console.log("signed in")
    userStore.userSignedIn = true
    userStore.userAuth = {
      displayName: firebaseUser.displayName,
      email: firebaseUser.email,
    }

    // get ACL claims from token
    const token = await firebaseUser.getIdTokenResult()
    const claims = token?.claims
    const acl = claims?.acl || {}
    userStore.userAcl = acl
    // console.log({acl})

    // if user does not have admin role, just loggoff immediately and go back to auth
    // without using blocking functions, there is no way to prevent Google Sign In user from accessing the app
    // doesn't matter though, because API won't return anything without admin claims anyway
    // !!! on first deploy, you need to disable this or you need to set custom claims for the user programatically
    if (acl.role !== "admin") {
      console.log("not admin -> signing out")
      signOut(auth)
      return {name: "AuthView"}
    }

    // re-init api
    initApi({appId: "admin", firebaseUser})

    // get user data
    await userStore.get({userId: firebaseUser.uid})

    const redirectedFrom = router.currentRoute?.value?.redirectedFrom

    // console.log({redirectedFrom})

    // redirected? -> follow redirect
    if (redirectedFrom && redirectedFrom.fullPath !== "/") {
      // console.log("redirecting to:", redirectedFrom)
      router.push(redirectedFrom)
    } else {
      // go where supposed to go
      router.push({
        name: "DashboardView",
      })
    }
  }
  // on sign out
  else if (currentRouteRequiresAuth && currentRouteName !== "AuthView") {
    console.log("signed out -> redirecting to AuthView")
    userStore.user = {}
    userStore.userAuth = {}
    userStore.userAcl = {}
    userStore.userSignedIn = false
    router.push({
      name: "AuthView",
    })
  }

  // mock some delay on dev machine
  if (import.meta.env.VITE_ENVIRONMENT === "local_dev") {
    await new Promise((resolve) => setTimeout(resolve, 1000))
  }
  app.auth.initialUserCheckDone = true
})

export {router}
