<script setup>
import {computed, ref} from "vue"
import {Check, ChevronDown} from "lucide-vue-next"
import {cn} from "../../lib/utils.js"
import {Button} from "../shadcn-vue/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../shadcn-vue/command"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../shadcn-vue/form"
import {Popover, PopoverContent, PopoverTrigger} from "../shadcn-vue/popover"
import enumsCountriesTiny from "../../../enums/countriesTiny.js"
import {hasFlag} from "country-flag-icons"
import * as countryFlagIcons from "country-flag-icons/string/1x1"

const model = defineModel()
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  label: {
    type: [Boolean, String],
  },
  sublabel: {
    type: [Boolean, String],
  },
  placeholder: {
    type: [Boolean, String],
    default: "Please select",
  },
  caption: {
    type: [Boolean, String],
  },
  showFilter: {
    type: Boolean,
    default: false,
  },
  showFlags: {
    type: Boolean,
    default: false,
  },
})

const open = ref(false)

const selectedLabel = computed(() => {
  return props.options.find((option) => option.value === model.value).label
})

function onClickOption(option) {
  model.value = option.value
  open.value = false
}

function onInteractOutside() {
  if (open.value) {
    open.value = false
  }
}

function getFlag(countryCode) {
  const country = enumsCountriesTiny.find((c) => c.iso3 === countryCode)
  const iso2 = country.iso2
  // console.log(`get flag ${countryCode} -> ${iso2}`)
  if (hasFlag(iso2)) {
    return countryFlagIcons[iso2]
  }
  return null
}
</script>

<template>
  <FormItem class="flex flex-col">
    <!-- label -->
    <FormLabel class="text-neutral-700 text-lg" v-if="label">{{ label }}</FormLabel>
    <!-- sublabel -->
    <FormLabel v-if="sublabel" class="text-neutral-500 mt-1">{{ sublabel }}</FormLabel>

    <!-- ui -->
    <Popover :open>
      <PopoverTrigger as-child>
        <FormControl>
          <Button
            variant="outline"
            role="combobox"
            :class="cn('justify-between bg-white')"
            @click="open = !open">
            <span class="text-neutral-400" v-if="!model">{{
              placeholder
            }}</span>
            <span class="flex items-center" v-else>
              <!-- country flag -->
              <div
                class="size-5 rounded-full overflow-hidden mr-2"
                v-html="getFlag(model)"
                v-if="showFlags"></div>
              {{ selectedLabel }}</span
            >
            <ChevronDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent class="p-0" @interactOutside="onInteractOutside">
        <Command>
          <CommandInput :placeholder v-if="showFilter" />
          <CommandEmpty>Nothing found.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              <CommandItem
                v-for="option in options"
                :key="option.value"
                :value="option.label"
                @select="onClickOption(option)">
                <Check
                  :class="
                    cn(
                      'mr-2 h-4 w-4',
                      model?.includes(option.value)
                        ? 'opacity-100'
                        : 'opacity-0'
                    )
                  " />
                <!-- country flag -->
                <div
                  class="size-5 rounded-full overflow-hidden mr-2"
                  v-html="getFlag(option.value)"
                  v-if="showFlags"></div>
                <!-- Display main label -->
                <div>{{ option.label }}</div>
                <!-- Display sublabel -->
                <small class="text-secondary">{{ option.sublabel }}</small>
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>

    <!-- caption -->
    <FormDescription v-if="caption">
      {{ caption }}
    </FormDescription>

    <!-- message -->
    <FormMessage />
  </FormItem>
</template>
