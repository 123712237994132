<script setup>
import {computed, onMounted} from "vue"
import {Bold, Italic, Underline} from "lucide-vue-next"
import {Label} from "../shadcn-vue/label"
import {
  FormItem,
  FormLabel,
  FormDescription,
  FormMessage,
} from "../shadcn-vue/form"
import {Slider} from "../shadcn-vue/slider"

const props = defineProps({
  modelValue: {
    type: Number,
  },
  name: {
    type: String,
  },
  options: {
    type: Array,
  },
  label: {
    type: [Boolean, String, Number],
  },
  labelMin: {
    type: String,
  },
  labelMax: {
    type: String,
  },
  caption: {
    type: String,
  },
  min: {
    type: Number,
  },
  max: {
    type: Number,
  },
  step: {
    type: Number,
  },
  initial: {
    type: Number,
  },
})

const emit = defineEmits(["update:modelValue"])

const modelAsArray = computed(() => [props.modelValue])

function onSliderChange(value) {
  emit("update:modelValue", value?.[0])
}

function init() {
  if (props.initial) {
    emit("update:modelValue", props.initial)
  }
}

onMounted(init)
</script>

<template>
  <FormItem>
    <!-- label -->
    <FormLabel class="block mb-4 text-neutral-700" v-if="label">{{ label }}</FormLabel>

    <!-- min/max labels -->
    <div class="flex flex-row justify-between" v-if="labelMin || labelMax">
      <span class="text-neutral-500 text-xs" v-if="labelMin">{{
        labelMin
      }}</span>
      <span class="text-neutral-500 text-xs" v-if="labelMax">{{
        labelMax
      }}</span>
    </div>

    <!-- ui -->
    <div class="flex flex-row flex-wrap gap-1">
      <Slider
        :modelValue="modelAsArray"
        :default-value="[initial]"
        :min
        :max
        :step
        @update:modelValue="onSliderChange" />
    </div>

    <!-- caption -->
    <FormDescription class="pt-3" v-if="caption">{{ caption }}</FormDescription>

    <!-- message -->
    <FormMessage />
  </FormItem>
</template>
