import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import UAParser from "ua-parser-js"
const uaParser = new UAParser()

export const useAppStore = defineStore("app", () => {
  // state
  const app = ref({
    scrollY: 0,
    auth: {
      initialUserCheckDone: false,
    },
    header: {
      slot: null
    },
    menuDesktop: {
      elAvatar: null,
    },
    viewport: {
      width: null,
      height: null,
      size: null, // approximated breakpoint
      currentBreakpoint: null,
      breakpoints: {
        // has to be same as tailwind.config.cjs
        xs: 375,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
    pwa: {
      installable: "standalone" in window.navigator,
      // installed: false, // iOS can't detect
      running: window.navigator.standalone === true,
    },
    userAgent: uaParser.getResult(),
  })

  // actions
  function setViewportWidth(args) {
    const width = args
    app.value.viewport.width = width

    // breakpoint
    let cb = "xl"
    if (width < app.value.viewport.breakpoints.xl) cb = "lg"
    if (width < app.value.viewport.breakpoints.lg) cb = "md"
    if (width < app.value.viewport.breakpoints.md) cb = "sm"
    if (width < app.value.viewport.breakpoints.sm) cb = "xs"
    if (width < app.value.viewport.breakpoints.xs) cb = "_def"
    app.value.viewport.currentBreakpoint = cb

    // viewport size
    let vs = null
    if (["_def", "xs"].includes(cb)) vs = "mobile"
    if (["sm", "md"].includes(cb)) vs = "tablet"
    if (["lg", "xl"].includes(cb)) vs = "desktop"
    app.value.viewport.size = vs
  }

  function setViewportHeight(args) {
    const height = args
    app.value.viewport.height = height
  }

  return {
    app,
    setViewportWidth,
    setViewportHeight,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
