import {cva} from "class-variance-authority"

export {default as Toaster} from "./Toaster.vue"
export {default as Toast} from "./Toast.vue"
export {default as ToastViewport} from "./ToastViewport.vue"
export {default as ToastAction} from "./ToastAction.vue"
export {default as ToastClose} from "./ToastClose.vue"
export {default as ToastTitle} from "./ToastTitle.vue"
export {default as ToastDescription} from "./ToastDescription.vue"
export {default as ToastProvider} from "./ToastProvider.vue"
export {toast, useToast} from "./use-toast"

export const toastVariants = cva(
  "group pointer-events-auto relative flex items-center justify-between space-x-4 overflow-hidden rounded-full border px-6 py-3 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[--radix-toast-swipe-end-x] data-[swipe=move]:translate-x-[--radix-toast-swipe-move-x] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-top-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-top-full max-w-96",
  {
    variants: {
      variant: {
        default: "border bg-white text-neutral-800",
        destructive:
          "destructive group border-red-700 bg-red-500 text-red-100",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)
