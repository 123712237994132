import enumsEthnicities from "../enums/ethnicities.js"

const optionsEthnicities = enumsEthnicities.map((ethnicity) => {
  return {
    value: ethnicity.id,
    label: ethnicity.title,
  }
})

export default optionsEthnicities
