export default [
  {id: "LPC", title: "Licensed Professional Counselor (LPC)"},
  {id: "LCSW", title: "Licensed Clinical Social Worker (LCSW)"},
  {id: "LMFT", title: "Licensed Marriage and Family Therapist (LMFT)"},
  {id: "LMHC", title: "Licensed Mental Health Counselor (LMHC)"},
  {id: "LP", title: "Licensed Psychologist"},
  {id: "LCP", title: "Licensed Clinical Psychologist"},
  {id: "LPCC", title: "Licensed Professional Clinical Counselor (LPCC)"},
  {id: "LICSW", title: "Licensed Independent Clinical Social Worker (LICSW)"},
  {id: "LADC", title: "Licensed Alcohol and Drug Counselor (LADC)"},
  {id: "LMSW", title: "Licensed Master Social Worker (LMSW)"},
  {id: "CRC", title: "Certified Rehabilitation Counselor (CRC)"},
  {id: "LPsyA", title: "Licensed Psychoanalyst"},
  {id: "CCMHC", title: "Certified Clinical Mental Health Counselor (CCMHC)"},
  {id: "CAC", title: "Certified Addiction Counselor (CAC)"},
  {id: "LBS", title: "Licensed Behavioral Specialist (LBS)"},
  {id: "CMFT", title: "Certified Marriage and Family Therapist (CMFT)"},
  {id: "CSC", title: "Certified School Counselor"},
  {id: "RPT", title: "Registered Play Therapist (RPT)"},
  {id: "CTS", title: "Certified Trauma Specialist (CTS)"},
  {id: "LAC", title: "Licensed Associate Counselor (LAC)"},
  {id: "LPA", title: "Licensed Psychological Associate (LPA)"},
  {id: "BCBA", title: "Board Certified Behavior Analyst (BCBA)"},
  {id: "CEDS", title: "Certified Eating Disorders Specialist (CEDS)"},
  {
    id: "PC",
    title:
      "Pastoral Counselor (various certifications depending on the organization)",
  },
  {id: "CGC", title: "Certified Grief Counselor (CGC)"},
]
