import enumsTherapySessionStatuses from "../enums/therapySessionStatuses.js"

const optionsTherapySessionStatuses = enumsTherapySessionStatuses.map(
  (status) => {
    return {
      value: status.id,
      label: status.title,
    }
  }
)

export default optionsTherapySessionStatuses
