<script setup>
import {ref} from "vue"
import {useFiles} from "../../composables/files.js"
import {AleaProgress} from "./index.js"
import {Input} from "../shadcn-vue/input"
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../shadcn-vue/form"

const model = defineModel()
const props = defineProps({
  kind: {
    type: String,
    required: true,
  },
  label: {
    type: [Boolean, String],
  },
  caption: {
    type: [Boolean, String],
  },
  // showUploadButton: {
  //   type: Boolean,
  //   default: true,
  // },
  accept: {
    type: String,
    default: "*/*",
  },
  autoUpload: {
    type: Boolean,
    default: true,
  },
  uploadOnBehalfUserId: {
    type: String,
  },
})

const {upload, progress, isUploading, error} = useFiles({
  kind: props.kind,
  uploadOnBehalfUserId: props.uploadOnBehalfUserId,
})

const file = ref(null)

async function onFileChange(event) {
  file.value = event.target.files[0]
  if (props.autoUpload) {
    await doUpload()
  }
}

// async function onClickUpload() {
//   await doUpload()
// }

async function doUpload() {
  console.log('doUpload', file.value)
  if (!file.value) {
    return
  }
  const {fileId} = await upload(file.value)

  // since file is uploaded separately (and by this time already finished, replace the form value with it's reference (fileId) instead of the actual file object
  model.value = fileId
}

defineExpose({
  upload,
  progress,
  isUploading,
  error,
})
</script>

<template>
  <FormItem>
    <!-- label -->
    <FormLabel v-if="label">{{ label }}</FormLabel>

    <!-- ui -->
    <FormControl>
      <div class="grid w-full max-w-sm items-center gap-1.5 relative">
        <!-- progress -->
        <div
          class="absolute flex justify-start items-center first-letter:z-10 w-full h-full bg-violet-500 opacity-98 duration-300 transition-all ease-in-out pl-4 text-white rounded-md text-sm"
          v-show="isUploading">
          <AleaProgress class="size-5" />{{ progress }} %
        </div>

        <!-- file input -->
        <Input id="file" type="file" :accept @change="onFileChange" />
      </div>
    </FormControl>

    <!-- <AleaButton
      label="upload"
      size="xs"
      @click="onClickUpload"
      :disabled="isUploading" />
    <div v-if="isUploading">Progress: {{ progress }}%</div>
    <div v-if="error">Error: {{ error }}</div> -->

    <!-- caption -->
    <FormDescription v-if="caption">{{ caption }}</FormDescription>

    <!-- message -->
    <FormMessage />
  </FormItem>
</template>
