import enumsTherapyPreferredTime from "../enums/therapyPreferredTime.js"

const optionsTherapyPreferredTime = enumsTherapyPreferredTime.map(
  (ageGroup) => {
    return {
      value: ageGroup.id,
      label: ageGroup.title,
    }
  }
)

export default optionsTherapyPreferredTime
