<script setup lang="ts">
import {Bold, Italic, Underline} from "lucide-vue-next"
import {ToggleGroup, ToggleGroupItem} from "../shadcn-vue/toggle-group"
import {Label} from "../shadcn-vue/label"
import {
  FormItem,
  FormLabel,
  FormDescription,
  FormMessage,
} from "../shadcn-vue/form"

const model = defineModel()
const props = defineProps({
  name: {
    type: String,
  },
  options: {
    type: Array,
  },
  label: {
    type: [Boolean, String, Number],
  },
  caption: {
    type: String,
  },
})
</script>

<template>
  <FormItem>
    <!-- label -->
    <FormLabel class="text-neutral-700" v-if="label">{{ label }}</FormLabel>

    <!-- options -->
    <div class="flex flex-row flex-wrap gap-1">
      <div
        class="border border-neutral-200 dark:border-neutral-800 h-10 px-3 inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors hover:bg-neutral-100 hover:text-neutral-500 disabled:pointer-events-none disabled:opacity-50 gap-1 cursor-pointer"
        :class="[
          {
            'bg-neutral-00 border-violet-500 outline outline-violet-500':
              model === option.value,
          },
        ]"
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        variant="outline"
        @click="model = option.value">
        {{ option.value }}
      </div>
    </div>

    <!-- caption -->
    <FormDescription v-if="caption">{{ caption }}</FormDescription>

    <!-- message -->
    <FormMessage />
  </FormItem>
</template>
