export default [
  {
    id: "white",
    title: "White",
  },
  {
    id: "blackOrAfricanAmerican",
    title: "Black or african american",
  },
  {
    id: "hispanicOrLatino",
    title: "Hispanic or latino",
  },
  {
    id: "asian",
    title: "Asian",
  },
  {
    id: "nativeAmerican",
    title: "Native american",
  },
  {
    id: "pacificIslander",
    title: "Pacific islander",
  },
  {
    id: "arab",
    title: "Arab",
  },
]
