<script setup>
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../shadcn-vue/form"
import {Textarea} from "../shadcn-vue/textarea"

const model = defineModel()
const props = defineProps({
  label: {
    type: [Boolean, String],
  },
  placeholder: {
    type: [Boolean, String],
  },
  caption: {
    type: [Boolean, String],
  },
  class: {
    type: [String, undefined],
    default: undefined,
  },
})
</script>

<template>
  <FormItem>
    <!-- label -->
    <FormLabel v-if="label">{{ label }}</FormLabel>

    <!-- ui -->
    <FormControl>
      <Textarea :class :placeholder v-model="model" />
    </FormControl>

    <!-- caption -->
    <FormDescription v-if="caption">{{ caption }}</FormDescription>

    <!-- message -->
    <FormMessage />
  </FormItem>
</template>
