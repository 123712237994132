<script setup>
import {cn} from "../../lib/utils.js"
import {Loader2} from "lucide-vue-next"

const props = defineProps({
  class: {
    type: String,
    default: "",
  },
})
</script>

<template>
  <Loader2 :class="cn('size-8 mr-2 animate-spin', props.class)" />
</template>
