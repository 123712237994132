<script setup>
import {computed, ref, watch} from "vue"
import {Avatar, AvatarFallback, AvatarImage} from "../shadcn-vue/avatar"
import {filesGetSignedUrl} from "@aleahealth/common"

const props = defineProps({
  user: {
    type: Object,
  },
  userId: {
    type: String,
  },
  userName: {
    type: String,
  },
})

const src = ref(null)

const userId = computed(() => props.user?.id || props.userId)
const userNameInitials = computed(() => {
  if (!props.userName) return ""
  return props.userName
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase()
})

async function init() {
  const res = await filesGetSignedUrl(
    {
      kind: "avatar",
      action: "read",
      userId: userId.value,
    },
    {notifySuccess: false}
  )
  src.value = res.response?.data?.url
}

// onMounted(init)

watch(userId, init, {immediate: true})

defineExpose({
  init,
})
</script>

<template>
  <Avatar>
    <AvatarImage :src />
    <AvatarFallback>{{ userNameInitials }}</AvatarFallback>
  </Avatar>
</template>
