import enumsTherapyPlatformsExperience from "../enums/therapyPlatformsExperience.js"

const optionsTherapyPlatformsExperience = enumsTherapyPlatformsExperience.map(
  (platform) => {
    return {
      value: platform.id,
      label: platform.title,
    }
  }
)

export default optionsTherapyPlatformsExperience
