<script setup>
  import { computed } from 'vue'
  import { Check, ChevronDown } from 'lucide-vue-next'
  import { cn } from '../../lib/utils.js'
  import { Button } from '../shadcn-vue/button'
  import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList
  } from '../shadcn-vue/command'
  import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
  } from '../shadcn-vue/form'
  import { Popover, PopoverContent, PopoverTrigger } from '../shadcn-vue/popover'

  const model = defineModel()
  const props = defineProps({
    options: {
      type: Array,
      required: true
    },
    label: {
      type: [Boolean, String]
    },
    placeholder: {
      type: [Boolean, String],
      default: 'Please select'
    },
    caption: {
      type: [Boolean, String]
    },
    showFilter: {
      type: Boolean,
      default: false
    }
  })

  const selectedValues = computed({
    get: () => model.value || [],
    set: (newValue) => {
      model.value = newValue
    }
  })

  const selectedLabels = computed(() => {
    return selectedValues.value.map((value) => {
      return props.options.find((option) => option.value === value).label
    })
  })

  function onClickOption(option) {
    const newSelectedValues = [...selectedValues.value]
    const index = newSelectedValues.indexOf(option.value)
    if (index > -1) {
      newSelectedValues.splice(index, 1)
    } else {
      newSelectedValues.push(option.value)
    }
    selectedValues.value = newSelectedValues
  }

  function onInteractOutside() {
    if (open.value) {
      open.value = false
    }
  }
</script>

<template>
  <FormItem class="flex flex-col">
    <!-- label -->
    <FormLabel class="text-neutral-700 text-lg" v-if="label">{{ label }}</FormLabel>

    <!-- ui -->
    <Popover>
      <PopoverTrigger as-child>
        <FormControl>
          <Button variant="outline" role="combobox" :class="cn('justify-between bg-white')">
            <span class="text-neutral-400" v-if="!selectedValues.length">{{ placeholder }}</span>
            <span v-else>
              {{
                selectedLabels.join(', ').slice(0, 40) +
                (selectedLabels.join(', ').length > 40 ? '...' : '')
              }}
            </span>
            <ChevronDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent class="p-0" @interactOutside="onInteractOutside">
        <Command>
          <CommandInput :placeholder v-if="showFilter" />
          <CommandEmpty>Nothing found.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              <CommandItem
                v-for="option in options"
                :key="option.value"
                :value="option.label"
                @select="onClickOption(option)"
              >
                <Check
                  :class="
                    cn('mr-2 h-4 w-4', model?.includes(option.value) ? 'opacity-100' : 'opacity-0')
                  "
                />
                {{ option.label }}
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>

    <!-- caption -->
    <FormDescription v-if="caption">
      {{ caption }}
    </FormDescription>

    <!-- message -->
    <FormMessage />
  </FormItem>
</template>
