export default [
  // {
  //   id: "13-17",
  //   title: "13-17",
  // },
  {
    id: "18-24",
    title: "18-24",
  },
  {
    id: "25-34",
    title: "25-34",
  },
  {
    id: "35-44",
    title: "35-44",
  },
  {
    id: "45-54",
    title: "45-54",
  },
  {
    id: "55-64",
    title: "55-64",
  },
  {
    id: "65+",
    title: "65+",
  },
]
