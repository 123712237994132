import enumsClientTypes from "../enums/clientTypes.js"

const optionsClientTypes = enumsClientTypes.map((clientType) => {
  return {
    value: clientType.id,
    label: clientType.title, // Main title
    sublabel: clientType.subtitle // Sublabel
  }
})

export default optionsClientTypes
