<script setup>
import {computed} from "vue"
import {CheckboxIndicator, CheckboxRoot, useForwardPropsEmits} from "radix-vue"
import {Check} from "lucide-vue-next"
import {cn} from "../../../lib/utils"

const props = defineProps({
  defaultChecked: {type: Boolean, required: false},
  checked: {type: [Boolean, String], required: false},
  disabled: {type: Boolean, required: false},
  required: {type: Boolean, required: false},
  name: {type: String, required: false},
  value: {type: String, required: false},
  id: {type: String, required: false},
  asChild: {type: Boolean, required: false},
  as: {type: null, required: false},
  class: {type: null, required: false},
})
const emits = defineEmits(["update:checked"])

const delegatedProps = computed(() => {
  const {class: _, ...delegated} = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <CheckboxRoot
    v-bind="forwarded"
    :class="
      cn(
        'peer size-5 shrink-0 rounded-md border border-neutral-300 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-violet-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-violet-600 data-[state=checked]:border-violet-600 data-[state=checked]:text-neutral-50',
        props.class
      )
    ">
    <CheckboxIndicator
      class="flex h-full w-full items-center justify-center text-current">
      <slot>
        <Check class="h-4 w-4" />
      </slot>
    </CheckboxIndicator>
  </CheckboxRoot>
</template>
