export function getCamelCase(string) {
  if (!string) return ""
  const newString = string
    .replace(/[^a-zA-Z0-9]/g, " ")
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join("")
  return newString
}

export function randomKey(length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, 2 + length)
}

// firestore-like id
// https://github.com/firebase/firebase-js-sdk/blob/master/packages/firestore/src/util/misc.ts#L40-L50
export function randomId(length = 20) {
  // Alphanumeric characters
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  // The largest byte value that is a multiple of `char.length`.
  const maxMultiple = Math.floor(256 / chars.length) * chars.length

  let autoId = ""
  while (autoId.length < length) {
    const bytes = randomBytes(40)
    for (let i = 0; i < bytes.length; ++i) {
      // Only accept values that are [0, maxMultiple), this ensures they can
      // be evenly mapped to indices of `chars` via a modulo operation.
      if (autoId.length < length && bytes[i] < maxMultiple) {
        autoId += chars.charAt(bytes[i] % chars.length)
      }
    }
  }
  return autoId
}

export function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function randomBytes(byteLength) {
  let result = new Uint8Array(byteLength)
  for (let i = 0; i < byteLength; i++) {
    result[i] = Math.floor(Math.random() * 256)
  }
  return result
}

export function parseTimeWithTimezone(inputTime) {
  const [time, timezone] = inputTime.split("+")
  const [hours, minutes, seconds] = time.split(":")
  const HHmm = `${hours}:${minutes}`

  return {
    hours,
    minutes,
    seconds,
    timezone,
    HHmm,
  }
}