<script setup>
import {computed, ref} from "vue"
import AleaButton from "./AleaButton.vue"
import {Grip, LogOut} from "lucide-vue-next"
import {auth} from "@aleahealth/common/apps/firebase/index.js"
import {signOut} from "firebase/auth"
import AleaViewMenuMobileDialog from "./AleaViewMenuMobileDialog.vue"
import AlewViewMenuMobileItem from "./AleaViewMenuMobileItem.vue"

const props = defineProps({
  menuItemsEnriched: {
    type: Array,
    required: true,
  },
})

const showDialog = ref(false)

const menuItemsEnrichedFirst4 = computed(() => {
  return props.menuItemsEnriched.slice(0, 4)
})

const menuItemsEnrichedLast = computed(() => {
  return props.menuItemsEnriched.slice(4)
})
</script>

<template>
  <!-- dialog menu -->
  <AleaViewMenuMobileDialog :open="showDialog">
    <AleaButton
      class="rounded-lg flex flex-row gap-1 items-center"
      :variant="item.active ? 'ghostSelected' : 'ghost'"
      align="left"
      :icon="item.icon"
      :label="item.title"
      :route="item.route"
      v-for="item in menuItemsEnrichedLast"
      :key="item.id"
      @click="showDialog = false" />

    <AleaButton
      class="rounded-lg w-full flex flex-row gap-1 items-center text-red-500"
      align="left"
      variant="ghost"
      :icon="LogOut"
      label="sign out"
      @click="signOut(auth)" />
  </AleaViewMenuMobileDialog>

  <div
    class="fixed bottom-0 z-20 flex flex-row justify-between left-0 right-0 px-4 py-2 bg-white shadow-[0_-4px_12px_0px_rgba(201,171,245,0.08)] md:hidden">
    <!-- items -->
    <AlewViewMenuMobileItem
      :item
      v-for="item in menuItemsEnrichedFirst4"
      :key="item.id"
      @itemClicked="showDialog = false" />

    <!-- menu -->
    <AlewViewMenuMobileItem
      :item="{title: 'menu', icon: Grip}"
      @click="showDialog = !showDialog" />
  </div>
</template>
