<script setup>
  import { ref, computed, watch, onMounted } from 'vue'
  import { Button } from '../shadcn-vue/button'
  import { FormControl, FormItem, FormLabel, FormMessage } from '../shadcn-vue/form'

  const props = defineProps({
    label: {
      type: [Boolean, String]
    },
    placeholder: {
      type: [Boolean, String]
    },
    caption: {
      type: [Boolean, String]
    }
  })
  const emit = defineEmits(['update:modelValue'])

  // Initialize state
  const hour = ref(12)
  const minute = ref(0)
  const period = ref('AM')

  // Methods to handle up/down increment/decrement
  function incrementHour() {
    hour.value = hour.value === 24 ? 1 : hour.value + 1
  }

  function decrementHour() {
    hour.value = hour.value === 1 ? 24 : hour.value - 1
  }

  function incrementMinute() {
    minute.value = minute.value === 55 ? 0 : minute.value + 5
  }

  function decrementMinute() {
    minute.value = minute.value === 0 ? 55 : minute.value - 5
  }

  // Method to handle period selection
  function setPeriod(newPeriod) {
    period.value = newPeriod
  }

  // Emit the combined time value
  const timeValue = computed(() => {
    return `${hour.value.toString().padStart(2, '0')}:${minute.value.toString().padStart(2, '0')}`
  })

  watch([hour, minute, period], () => {
    emit('update:modelValue', timeValue.value)
  })

  function init() {
    emit('update:modelValue', timeValue.value)
  }

  onMounted(init)
</script>

<template>
  <FormItem class="flex flex-col">
    <!-- Label -->
    <FormLabel class="text-neutral-700 text-lg" v-if="label">{{ label }}</FormLabel>

    <!-- Time Picker Fields -->
    <div class="flex items-center gap-4">
      <!-- Hour Field -->
      <div class="flex flex-col items-center">
        <div class="flex space-x-2">
          <Button
            variant="outline"
            size="sm"
            class="p-2 text-violet-400 dark:text-violet-100"
            type="button"
            @click="incrementHour"
          >
            ▲
          </Button>
          <FormControl>
            <input
              type="text"
              readonly
              :value="hour.toString().padStart(2, '0')"
              class="text-center w-12 border border-neutral-300 rounded-md"
            />
          </FormControl>
          <Button
            variant="outline"
            size="sm"
            class="p-2 text-violet-400 dark:text-violet-100"
            type="button"
            @click="decrementHour"
          >
            ▼
          </Button>
        </div>
      </div>

      <!-- Minute Field -->
      <div class="flex flex-col items-center">
        <div class="flex space-x-2">
          <Button
            variant="outline"
            size="sm"
            class="p-2 text-violet-400 dark:text-violet-100"
            type="button"
            @click="incrementMinute"
          >
            ▲
          </Button>
          <FormControl>
            <input
              type="text"
              readonly
              :value="minute.toString().padStart(2, '0')"
              class="text-center w-12 border border-neutral-300 rounded-md"
            />
          </FormControl>
          <Button
            variant="outline"
            size="sm"
            class="p-2 text-violet-400 dark:text-violet-100"
            type="button"
            @click="decrementMinute"
          >
            ▼
          </Button>
        </div>
      </div>

      <!-- AM/PM Selection -->
      <!--
      <div class="flex items-center space-x-2">
        <Button
          :variant="period === 'AM' ? 'secondary' : 'outline'"
          size="sm"
          class="p-2"
          type="button"
          @click="setPeriod('AM')"
        >
          AM
        </Button>
        <Button
          :variant="period === 'PM' ? 'secondary' : 'outline'"
          size="sm"
          class="p-2"
          type="button"
          @click="setPeriod('PM')"
        >
          PM
        </Button>
      </div>
      -->
    </div>

    <!-- Caption -->
    <FormDescription v-if="caption">
      {{ caption }}
    </FormDescription>

    <!-- Message -->
    <FormMessage />
  </FormItem>
</template>
