import MainView from "../views/MainView.vue"
import AuthView from "../views/AuthView.vue"

export const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "DashboardView",
        component: () => import("../views/DashboardView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "dashboard",
        },
      },
      {
        path: "users",
        name: "UsersView",
        component: () => import("../views/UsersView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "users",
        },
        children: [
          {
            path: ":userId",
            name: "UsersDetailView",
            component: () => import("../views/UsersDetailView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "user",
            },
          },
        ],
      },
      {
        path: "clients",
        name: "ClientsView",
        component: () => import("../views/ClientsView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "clients",
        },
        children: [
          {
            path: ":clientId",
            name: "ClientsDetailView",
            component: () => import("../views/ClientsDetailView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "client",
            },
          },
        ],
      },
      {
        path: "providers",
        name: "ProvidersView",
        component: () => import("../views/ProvidersView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "providers",
        },
        children: [
          {
            path: ":providerId",
            name: "ProvidersDetailView",
            component: () => import("../views/ProvidersDetailView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "provider",
            },
            children: [
              {
                path: "educations/:educationId?",
                name: "ProvidersDetailEducationsDetailView",
                component: () => import("../views/ProvidersDetailEducationsDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "education",
                },
              },
              {
                path: "licenses/:licenseId",
                name: "ProvidersDetailLicensesDetailView",
                component: () => import("../views/ProvidersDetailLicensesDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "license",
                },
              },
              {
                path: "insurances/:insuranceId",
                name: "ProvidersDetailInsurancesDetailView",
                component: () => import("../views/ProvidersDetailInsurancesDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "insurance",
                },
              },
              {
                path: "identifications/:identificationId",
                name: "ProvidersDetailIdentificationsDetailView",
                component: () => import("../views/ProvidersDetailIdentificationsDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "identification",
                },
              },
            ],
          },
        ],
      },
      {
        path: "sessions",
        name: "TherapiesSessionsView",
        component: () => import("../views/TherapiesSessionsView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "sessions",
        },
      },
      {
        path: "slots",
        name: "BookingSlotsView",
        component: () => import("../views/BookingSlotsView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "slots",
        },
      },
      {
        path: "emails",
        name: "EmailsView",
        component: () => import("../views/EmailsView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "emails",
        },
      },
      {
        path: "files",
        name: "FilesView",
        component: () => import("../views/FilesView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "files",
        },
      },
      {
        path: "user",
        name: "UserView",
        component: () => import("../views/UserView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "user",
        },
      },
    ],
  },
  {
    path: "/auth",
    name: "AuthView",
    component: AuthView,
  },
]
