import {request} from "../request.js"

export const usersCreate = async (data, opts) => {
  return request({
    endpoint: "users",
    method: "POST",
    data: {data},
    opts,
  })
}

export const usersGet = async (userId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append("include", opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = userId ? `users/${userId}${query}` : `users${query}`

  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const usersUpdate = async (userId, data, opts) => {
  return request({
    endpoint: `users/${userId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const usersDelete = async (userId, opts) => {
  return request({
    endpoint: `users/${userId}`,
    method: "DELETE",
    opts,
  })
}

export const usersPasswordSet = async (userId, password, opts) => {
  console.log({userId, password})
  return request({
    endpoint: `users/${userId}/set-password`,
    method: "POST",
    data: {data: {password}},
    opts,
  })
}

export const usersCheckEmail = async (email, opts) => {
  return request({
    endpoint: `users/check/email/${email}`,
    method: "GET",
    opts,
  })
}

export const usersOnboardingGet = async (userId, opts) => {
  return request({
    endpoint: `users/${userId}/onboarding`,
    method: "GET",
    opts,
  })
}

export const usersOnboardingUpdate = async (userId, stage, data, opts) => {
  return request({
    endpoint: `users/${userId}/onboarding/${stage}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}
