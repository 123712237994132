<script setup>
  import { computed, ref, watch, onMounted } from 'vue'
  import { auth } from '../../firebase/index.js'
  import {
    GoogleAuthProvider,
    signInWithPopup,
    linkWithPopup,
    // signInWithRedirect,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithCustomToken
  } from 'firebase/auth'
  import {
    initApi,
    AleaProgress,
    AleaButton,
    AleaPinInput,
    authInit,
    authUpgrade,
    authSignInWithOTP
  } from '@aleahealth/common'
  import { useAppStore } from '../../stores/app.js'
  import { storeToRefs } from 'pinia'
  import IconGoogle from '../../assets/img/icons/icon-google.svg'
  import { Button } from '../../components/shadcn-vue/button'
  import AleaCard from '../alea/AleaCard.vue'
  import AleaForm from '../alea/AleaForm.vue'
  import AleaSeparator from '../alea/AleaSeparator.vue'
  import * as z from 'zod'

  const props = defineProps({
    title: {
      type: [Boolean, String],
      required: false
    },
    description: {
      type: [Boolean, String],
      default: false
    },
    otpEnabled: {
      type: Boolean,
      default: true
    },
    submitLabel: {
      type: String,
      default: 'Sign in'
    },
    email: {
      type: [Boolean, String],
      default: false
    },
    // upgrade anonymous account to permanent account (used on signup)
    upgrade: {
      type: Boolean,
      default: false
    },
    submitProgress: {
      type: Boolean,
      default: false
    },
    showWrongEmailButton: {
      type: Boolean,
      default: true
    }
  })

  const emit = defineEmits(['signedIn'])

  const appStore = useAppStore()
  const { app } = storeToRefs(appStore)

  const elForm = ref(null)
  const email = ref(null)
  const currentStage = ref('init') // init | providePassword | provideOTP
  const progress = ref({
    submitInit: false,
    submitOTP: false
  })

  const termsAccepted = ref(false) // Reactive property for terms checkbox

  const fields = [
    {
      id: 'email',
      label: 'email',
      type: 'text',
      placeholder: 'peter@parker.com',
      ui: 'input',
      validation: z
        .string({ message: 'E-mail is required' })
        .email({ message: 'Provide valid email' })
    }
  ]

  const submitProgress = computed(() => {
    return props.submitProgress ? props.submitProgress : progress.value.submitInit
  })

  const providerGoogleEnabled = computed(() => {
    // google login in embedded browsers (eg. Instagram) is not supported (security reasons) and there is no way around it
    // seems to work in X and YouTube
    // console.log("UA:", app.value.userAgent)

    // so far the best way to detect webview - on ios, safari only inlcudes 'safari' in user agent string when it is not in webview
    return ['Safari', 'Mobile Safari', 'Chrome', 'Firefox'].includes(
      app.value.userAgent.browser.name
    )
  })

  async function signInWithGoogle() {
    const provider = new GoogleAuthProvider()

    try {
      // Upgrade anonymous account
      if (props.upgrade) {
        const result = await linkWithPopup(auth.currentUser, provider)
        console.log('Anonymous account successfully upgraded', result.user)

        emit('signedIn', { provider: 'google', user: result.user, upgrade: true })
      }
      // Regular sign in
      else {
        const result = await signInWithPopup(auth, provider)
        console.log('Signed in with Google', result.user)
        emit('signedIn', { provider: 'google', user: result.user })
      }
    } catch (error) {
      if (error.code === 'auth/credential-already-in-use') {
        console.log('This Google account is already linked to another Firebase account')
        // Here you might want to sign in with the existing account
      } else {
        console.error('Error during Google sign-in/upgrade', error)
      }
    }
  }

  async function initAuth() {
    console.log('initAuth', email.value)
    progress.value.submitInit = true

    // upgrade -> upgrade anonymous account to permanent account first
    if (props.upgrade) {
      const res = await authUpgrade({ email: email.value }, { notifySuccess: false })
    }

    const res = await authInit(
      {
        email: email.value,
        host: window.location.host
      },
      { notifySuccess: false }
    )

    // request OTP
    if ('provide-otp' === res.response.statusCode) {
      currentStage.value = 'provideOTP'
    }

    // request password
    if ('provide-password' === res.response.statusCode) {
      currentStage.value = 'providePassword'
    }

    progress.value.submitInit = false
  }

  async function signInUsingOTP(code) {
    progress.value.submitOTP = true
    console.log('signInUsingOTP', code)
    const res = await authSignInWithOTP(
      {
        email: email.value,
        code
      },
      { notifySuccess: false }
    )

    const status = res.response.statusCode
    const token = res.response.data?.token
    console.log({ status, token })

    // received token -> sign in
    if ('otp-token' === status && token) {
      const userCredential = await signInWithCustomToken(auth, token).catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('error', errorCode, errorMessage)
      })

      // upgrade -> update user & token in api SDK
      if (props.upgrade) {
        initApi({ apiKey: token, firebaseUser: userCredential.user })
      }

      console.log({ userCredential })

      emit('signedIn', {
        provider: 'otp',
        user: userCredential.user,
        upgrade: props.upgrade
      })
    }
    // error
    else {
    }
    progress.value.submitOTP = false
  }

  async function onClickSignIn(values) {
    email.value = values.email

    // stage: init
    if (currentStage.value === 'init') {
      initAuth()
    }
    // stage: provide password
    else if (currentStage.value === 'providePassword') {
      signInUsingPassword()
    }
    // stage: provide OTP
    else if (currentStage.value === 'provideOTP') {
      signInUsingOTP()
    }
  }

  function init() {
    if (props.email) {
      elForm.value.setFormValues({ email: props.email }, true)
    }
  }

  onMounted(() => {
    init()
  })
</script>

<template>
  <div class="max-w-xs w-full">
    <!-- current stage: init or provide password -->
    <AleaCard
      class="flex flex-col gap-2"
      :title
      :description
      v-if="['init', 'providePassword'].includes(currentStage)"
    >
      <AleaForm
        ref="elForm"
        :fields
        :submitLabel
        :submitProgress
        :submitDisabled="props.upgrade && !termsAccepted"
        :showCancel="false"
        :restrictWidth="false"
        @submit="onClickSignIn"
        v-if="otpEnabled"
      />

      <div v-if="props.upgrade" class="mt-4">
        <label class="flex items-center space-x-1.5 text-sm text-gray-600">
          <input type="checkbox" v-model="termsAccepted" class="h-4 w-4" />
          <span class="leading-tight">
            I agree to the
            <a
              href="https://aleahealth.co/terms-of-use"
              target="_blank"
              class="text-blue-600 underline"
            >
              Terms and Conditions
            </a>
          </span>
        </label>
      </div>

      <!-- providers -->
      <div>
        <AleaSeparator class="my-6" label="or" v-if="otpEnabled && providerGoogleEnabled" />
        <!-- google -->
        <Button
          class="flex-center gap-2 w-full"
          :class="[]"
          variant="outline"
          @click="signInWithGoogle()"
          :disabled="props.upgrade && !termsAccepted"
          v-if="providerGoogleEnabled"
        >
          <IconGoogle class="h-4 w-4" />
          <span>Sign in with Google</span>
        </Button>
      </div>
    </AleaCard>

    <!-- current stage: OTP -->
    <AleaCard
      class="flex flex-col gap-2 relative overflow-hidden"
      title="Check your email"
      :description="`We sent a code to ${email}`"
      v-if="currentStage === 'provideOTP'"
    >
      <!-- progress -->
      <div class="flex-center gap-2 absolute inset-0 z-10 bg-white/75" v-if="progress.submitOTP">
        <AleaProgress class="size-5" />
      </div>
      <AleaPinInput @complete="signInUsingOTP" />
      <AleaButton
        class="mt-4 px-0"
        label="wrong email?"
        variant="link"
        @click="currentStage = 'init'"
        v-if="showWrongEmailButton"
      />
    </AleaCard>
  </div>
</template>
