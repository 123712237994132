import enumsVerificationStatuses from "../enums/verificationStatuses.js"

const optionsVerificationStatuses = enumsVerificationStatuses.map(
  (verificationStatus) => {
    return {
      value: verificationStatus.id,
      label: verificationStatus.title,
    }
  }
)

export default optionsVerificationStatuses
