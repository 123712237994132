<script setup>
import {computed} from "vue"
import {ToastRoot, useForwardPropsEmits} from "radix-vue"
import {toastVariants} from "."
import {cn} from "../../../lib/utils"

const props = defineProps(["class", "variant", "onOpenChange"])

const emits = defineEmits(["update:open"])

const delegatedProps = computed(() => {
  const {class: _, ...delegated} = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <ToastRoot
    v-bind="forwarded"
    :class="cn(toastVariants({variant: props.variant}), props.class)"
    @update:open="props.onOpenChange">
    <slot />
  </ToastRoot>
</template>
