<script setup>
import {computed} from "vue"
import {RadioGroupIndicator, RadioGroupItem, useForwardProps} from "radix-vue"
import {Circle} from "lucide-vue-next"
import {cn} from "../../../lib/utils"

const props = defineProps({
  id: {type: String, required: false},
  value: {type: String, required: false},
  disabled: {type: Boolean, required: false},
  required: {type: Boolean, required: false},
  name: {type: String, required: false},
  asChild: {type: Boolean, required: false},
  as: {type: null, required: false},
  class: {type: null, required: false},
})

const delegatedProps = computed(() => {
  const {class: _, ...delegated} = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <RadioGroupItem
    v-bind="forwardedProps"
    :class="
      cn(
        'aspect-square h-4 w-4 rounded-full border border-neutral-900 text-neutral-900 ring-offset-white focus:outline-none focus-visible:ring-2 focus-visible:ring-violet-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 ',
        props.class
      )
    ">
    <RadioGroupIndicator class="flex items-center justify-center">
      <Circle class="h-2.5 w-2.5 fill-current text-current" />
    </RadioGroupIndicator>
  </RadioGroupItem>
</template>
