import enumsGenders from "../enums/genders.js"

const optionsGenders = enumsGenders.map((gender) => {
  return {
    value: gender.id,
    label: gender.title,
  }
})

export default optionsGenders
