import enumsAgeGroups from "../enums/ageGroups.js"

const optionsAgeGroups = enumsAgeGroups.map((ageGroup) => {
  return {
    value: ageGroup.id,
    label: ageGroup.title,
  }
})

export default optionsAgeGroups
