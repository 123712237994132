<script setup>
import {ToastProvider} from "radix-vue"

const props = defineProps()
</script>

<template>
  <ToastProvider v-bind="props">
    <slot />
  </ToastProvider>
</template>
