import enumsTherapyOrientations from "../enums/therapyOrientations.js"

const optionsTherapyOrientations = enumsTherapyOrientations.map(
  (therapyOrientation) => {
    return {
      value: therapyOrientation.id,
      label: therapyOrientation.title,
    }
  }
)

export default optionsTherapyOrientations
