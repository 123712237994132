import enumsMenutalHealthDisorders from "../enums/mentalHealthDisorders.js"

const optionsMenutalHealthDisorders = enumsMenutalHealthDisorders.map(
  (mentalHealthDisorder) => {
    return {
      value: mentalHealthDisorder.id,
      label: mentalHealthDisorder.title,
    }
  }
)

export default optionsMenutalHealthDisorders
