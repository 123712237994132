<script setup>
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <div class="fixed flex items-end inset-0 z-20 p-4 bg-black/80" v-if="open">
    <div class="bg-white p-4 mb-[70px] w-full rounded-2xl">
      <slot></slot>
    </div>
  </div>
</template>
