import enumsRoles from "../enums/roles.js"

const optionsRoles = enumsRoles.map((role) => {
  return {
    value: role.id,
    label: role.title,
  }
})

export default optionsRoles
