import {createApp} from "vue"
import {router} from "./router/index.js"
import App from "./App.vue"
import {createPinia} from "pinia"
import "@aleahealth/common/apps/assets/index.css"

const urlParams = new URLSearchParams(window.location.search)

// disable ga_tracking on this machine
const disableTracking = urlParams.get("disableTracking")
if (disableTracking) {
  const config = {ga_tracking: false}
  localStorage.setItem("@aleahealth/config", JSON.stringify(config))
}

window.app = {
  version: "__VERSION__",
  build: "__BUILD__",
  commit: "__RELEASE_SHA__",
  date: "__BUILD_DATE__",
}

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
app.use(router)

app.mount("#app")
