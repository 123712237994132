export default [
  {
    id: "anxiety",
    titleClient: "Feeling less anxious or stressed",
    titleProvider: "Reducing anxiety",
  },
  {
    id: "depression",
    titleClient: "Overcoming sadness or depression",
    titleProvider: "Managing depression",
  },
  {
    id: "trauma",
    titleClient: "Healing from past trauma",
    titleProvider: "Coping with trauma",
  },
  {
    id: "anger",
    titleClient: "Managing anger",
    titleProvider: "Managing anger",
  },
  {
    id: "relationships",
    titleClient: "Building better relationships",
    titleProvider: "Enhancing relationships",
  },
  {
    id: "confidence",
    titleClient: "Boosting my self-confidence",
    titleProvider: "Improving self-esteem",
  },
  {
    id: "changes",
    titleClient: "Managing life's big changes",
    titleProvider: "Managing life's big changes",
  },
  {
    id: "sleep",
    titleClient: "Improving my sleep",
    titleProvider: "Improving sleep",
  },
  {
    id: "family",
    titleClient: "Dealing with family issues",
    titleProvider: "Dealing with family issues",
  },
  {
    id: "career",
    titleClient: "Finding direction in my career",
    titleProvider: "Finding direction in my career",
  },
  {
    id: "grief",
    titleClient: "Coping with grief or loss",
    titleProvider: "Coping with grief or loss",
  },
  {
    id: "addiction",
    titleClient: "Overcoming addictive behaviors",
    titleProvider: "Addressing addiction",
  },
  {
    id: "bipolar",
    titleClient: "Managing mood swings",
    titleProvider: "Treating bipolar disorder",
  },
  {
    id: "adhd",
    titleClient: "Improving focus and attention",
    titleProvider: "Managing ADHD symptoms",
  },
  {
    id: "eating",
    titleClient: "Improving my relationship with food",
    titleProvider: "Addressing eating disorders",
  },
  {
    id: "personality",
    titleClient: "Managing intense emotions and relationships",
    titleProvider: "Treating personality disorders",
  },
  {
    id: "autismSpectrum",
    titleClient: "Navigating social situations",
    titleProvider: "Supporting autism spectrum challenges",
  },
  {
    id: "stress",
    titleClient: "Feeling less overwhelmed by stress",
    titleProvider: "Reducing stress",
  },
  {
    id: "sexuality",
    titleClient: "Understanding my feelings about sexuality",
    titleProvider: "Supporting sexuality exploration",
  },
  {
    id: "selfHarm",
    titleClient: "Overcoming self-harming behaviors",
    titleProvider: "Addressing self-harm",
  },
  {
    id: "psychosis",
    titleClient: "Understanding unusual thoughts or experiences",
    titleProvider: "Managing psychosis symptoms",
  },
  {
    id: "spirituality",
    titleClient: "Exploring my spiritual beliefs",
    titleProvider: "Supporting spiritual growth",
  },
  {
    id: "selfDoubt",
    titleClient: "Building confidence and overcoming self-doubt",
    titleProvider: "Improving self-esteem and confidence",
  },
  {
    id: "other",
    titleClient: "Other",
    titleProvider: "Other",
  },
]
