<script setup>
import {Separator} from "../shadcn-vue/separator"

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
})
</script>

<template>
  <Separator :label />
</template>
