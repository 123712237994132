<script setup>
import {ref} from "vue"
import {PinInput, PinInputGroup, PinInputInput} from "../shadcn-vue/pin-input"

const props = defineProps({
  length: {
    type: Number,
    default: 6
  },
  placeholder: {
    type: String,
    default: "○"
  }
})

const emit = defineEmits(["complete"])

const value = ref([])

function onComplete(e) {
  emit("complete", e.join(""))
}
</script>

<template>
  <div>
    <PinInput
      id="pin-input"
      v-model="value"
      :placeholder
      @complete="onComplete">
      <PinInputGroup>
        <PinInputInput v-for="(id, index) in length" :key="id" :index="index" type="text" maxlength="1" inputmode="numeric" pattern="\d*" />
      </PinInputGroup>
    </PinInput>
  </div>
</template>
