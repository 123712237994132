import {request} from "../request.js"

export const filesGetSignedUrl = async (data, opts) => {
  return request({
    endpoint: "files/get-signed-url",
    method: "POST",
    data: {data},
    opts,
  })
}

export const filesUploaded = async (fileId, data, opts) => {
  return request({
    endpoint: `files/uploaded/${fileId}`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const filesGet = async (fileId, opts) => {
  const params = new URLSearchParams()

  if (opts?.include) {
    params.append("include", opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = fileId ? `files/${fileId}${query}` : `files${query}`

  return request({
    endpoint,
    method: "GET",
    opts,
  })
}
