<script setup>
import {computed} from "vue"
import {ToastTitle} from "radix-vue"
import {cn} from "../../../lib/utils"

const props = defineProps(["class"])

const delegatedProps = computed(() => {
  const {class: _, ...delegated} = props

  return delegated
})
</script>

<template>
  <ToastTitle
    v-bind="delegatedProps"
    :class="cn('text-sm font-semibold', props.class)">
    <slot />
  </ToastTitle>
</template>
