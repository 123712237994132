import {ref} from "vue"
import {filesGetSignedUrl, filesUploaded} from "../../api/index.js"

export function useFiles({kind, uploadOnBehalfUserId}) {
  const progress = ref(0)
  const isUploading = ref(false)
  const error = ref(null)
  const signedUrl = ref(null)
  const fileId = ref(null)

  async function getSignedUrl({name, type, size}) {
    const res = await filesGetSignedUrl(
      {
        action: "write",
        kind: kind?.value ? kind.value : kind,
        name,
        type,
        size,
        onBehalfUserId: uploadOnBehalfUserId?.value
          ? uploadOnBehalfUserId.value
          : uploadOnBehalfUserId,
      },
      {notifySuccess: false}
    )
    signedUrl.value = res.response.data.url
    fileId.value = res.response.data.fileId
  }

  function upload(file) {
    return new Promise(async (resolve, reject) => {
      await getSignedUrl({
        name: file.name,
        type: file.type,
        size: file.size,
      })

      isUploading.value = true
      error.value = null
      progress.value = 0

      const xhr = new XMLHttpRequest()

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          progress.value = Math.round((event.loaded / event.total) * 100)
        }
      })

      xhr.addEventListener("load", async () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          console.log("File uploaded successfully.")
          await filesUploaded(fileId.value, undefined, {notifySuccess: false})
          resolve({fileId: fileId.value})
        } else {
          console.error("Failed to upload file.", xhr.statusText)
          error.value = xhr.statusText
          reject(new Error(xhr.statusText))
        }
      })

      xhr.addEventListener("error", () => {
        console.error("An unexpected error occurred.")
        error.value = "Unexpected error"
        reject(new Error("Unexpected error"))
      })

      xhr.addEventListener("loadend", () => {
        isUploading.value = false
      })

      xhr.open("PUT", signedUrl.value)
      xhr.setRequestHeader("Content-Type", file.type)
      xhr.send(file)
    })
  }

  return {
    upload,
    progress,
    isUploading,
    error,
  }
}
